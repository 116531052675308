<template>
  <div>
    <q-form ref="editForm">
      <c-card title="공사형태 상세" class="cardClassDetailForm">
        <template slot="card-button">
          <q-btn-group outline >
            <c-btn v-if="editable" label="공사형태 신규" icon="add" @btnClicked="addParent" />
            <c-btn
              v-if="editable"
              :disabled="!saveable"
              :url="saveUrl"
              :isSubmit="isSave"
              :param="data"
              :mappingType="saveType"
              label="저장"
              icon="save"
              @beforeAction="saveData"
              @btnCallback="saveCallback" />
            <c-btn
              v-if="editable && deleteable"
              :url="deleteUrl"
              :isSubmit="true"
              :param="data"
              mappingType="DELETE"
              label="삭제"
              icon="remove"
              @beforeAction="deleteData"
              @btnCallback="deleteCallback" />
          </q-btn-group>
        </template>
        <template slot="card-detail">
          <div class="col-6">
            <c-text
              :editable="editable && dataeditable"
              :required="true"
              label="공사형태명"
              name="constructTypeName"
              v-model="data.constructTypeName">
            </c-text>
          </div>
          <div class="col-3">
            <c-text
              :editable="false"
              label="공사형태코드"
              name="constructTypeCd"
              v-model="data.constructTypeCd">
            </c-text>
          </div>
          <div class="col-4">
            <c-text
              :editable="editable && dataeditable"
              :required="true"
              label="정렬순서"
              name="sortOrder"
              type="number"
              v-model="data.sortOrder">
            </c-text>
          </div>
        </template>
      </c-card>
    </q-form>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'process-manage',
  props: {
    parentProcessCd: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      attachable: false,
      data: {
        plantCd: null,
        processCd: '',
        processName: '',
        upProcessCd: '',
        processLevelCd: '',
        processDesc: '',
        managementDepts: '',
        sortOrder: '',
        mainMaterial: '',
        workerCnt: '',
        productName: '',
        companyCd: '',
        useFlag: 'Y',
        psmFlag: 'Y',
      },
      listUrl: '',
      detailUrl: '',
      checkUrl: '',
      insertUrl: '',
      updateUrl: '',
      deleteUrl: '',
      saveable: false,
      deleteable: false,
      editable: true,
      dataeditable: false,
      updateMode: false,
      isSave: false,
      saveUrl: transactionConfig.mdm.construct.insert.url,
      saveType: 'POST',
      searchUrl: '',
      selectedProcessCd: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  watch: {
    parentProcessCd() {
      if (this.parentProcessCd) {
        this.rowClick({ constructTypeCd: this.parentProcessCd })
      } else {
        this.reset();
      }
    }
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.dataeditable = false;
      // api scope
      this.detailUrl = selectConfig.mdm.construct.get.url;
      this.insertUrl = transactionConfig.mdm.construct.insert.url;
      this.updateUrl = transactionConfig.mdm.construct.update.url;
      this.deleteUrl = transactionConfig.mdm.construct.delete.url;
    },
    rowClick(row) {
      // 상세조회
      this.saveable = true;
      this.deleteable = true;
      this.rowNotSelected = false;
      this.updateMode = true;
      this.selectedProcessCd = row.constructTypeCd;
      this.$http.url = this.detailUrl;
      this.$http.param = {constructTypeCd: this.selectedProcessCd}
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.data = _result.data;
        this.attachable = true;
        this.saveUrl = this.updateUrl;
        this.saveType = 'PUT';
        this.dataeditable = true;
      },
      () => {
      });
    },
    addParent() {
      this.attachable = true;
      this.saveable = true;
      this.deleteable = false;
      this.saveUrl = this.insertUrl;
      this.saveType = 'POST';
      this.updateMode = false;
      this.dataeditable = true;
      this.data = {
        companyCd: this.$store.getters.user.companyCd,
        constructTypeCd: '',
        constructTypeName: '',
        sortOrder: '',
        useFlag: 'Y',
      };
    },
    reset() {
      this.saveable = false;
      this.deleteable = false;
      this.updateMode = false;
      this.attachable = false;
      this.dataeditable = false;
      this.data = {
        companyCd: this.$store.getters.user.companyCd,
        constructTypeCd: '',
        constructTypeName: '',
        sortOrder: '',
        useFlag: 'Y',
      };
      this.$emit('isNew');
    },
    saveData() {
      this.$refs['editForm'].validate().then(_result => {
        // 중복체크
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '저장하시겠습니까?',
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.data.regUserId = this.$store.getters.user.userId
              this.data.chgUserId = this.$store.getters.user.userId
              this.isSave = !this.isSave;
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    deleteData() {
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '삭제하시겠습니까?',
        // TODO : 필요시 추가하세요.
        type: 'warning', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, this.data.constructTypeCd);
          this.$http.type = 'DELETE';
          this.$http.request(() => {
            this.isSave = !this.isSave;
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    saveCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      if (this.saveType == 'POST') {
        // this.reset();
        this.selectedProcessCd = '';
      }
      this.reset();
      this.$emit('searchDetail');
    },
    deleteCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.$emit('searchDetail');
      this.reset();
      this.selectedProcessCd = '';
    },
  }
};
</script>
